

.css-10hburv-MuiTypography-root {
    margin: 0;
    font-family: "lato" !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    
}
[dir="rtl"] .css-10hburv-MuiTypography-root {
    font-family: "El Messiri" !important;
}